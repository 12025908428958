import type { CSSProperties } from 'react';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';

import { useTranslation } from 'react-i18next';
import { useLangStore } from 'stores';
import { MeasurementMap } from 'pages/Chat/constants';
import type { BaseChartProps, LineComparision } from 'types';
import { extractColumnFromCountColumn } from 'pages/Chat/utils';
import type { ApexChartsTitleStyle } from '../types';

interface ParetoChartProps extends Omit<BaseChartProps, 'data'> {
  lineColor?: CSSProperties['color'];
  barColor?: CSSProperties['color'];
  data: { line: number[]; column: number[] };
  xUnit?: string;
  yUnit?: string;
  yUnit2?: string;
  language?: string;
  line?: number;
  lineDirection?: LineComparision;
}

function ParetoChart(props: ParetoChartProps) {
  const {
    xCategories,
    data,
    barColor = '#40A9FF',
    lineColor = '#f62225',
    xUnit,
    yUnit,
    yUnit2,
    language,
    lineDirection,
    line,
  } = props;
  const { t } = useTranslation();
  const { lang } = useLangStore();

  const lng = language ?? lang;

  const xAxisTitle = xUnit && t(`${xUnit}`, { lng });

  const yUnitExtracted = extractColumnFromCountColumn(yUnit);
  const yUnit2Extracted = extractColumnFromCountColumn(yUnit2);

  const yAxisTitle =
    yUnit &&
    MeasurementMap[yUnitExtracted] &&
    t(MeasurementMap[yUnitExtracted], { lng });

  const yAxisTitle2 =
    yUnit2 &&
    MeasurementMap[yUnit2Extracted] &&
    t(MeasurementMap[yUnit2Extracted], { lng });

  const optimalColumnWidthPercent =
    20 + 60 / (1 + 30 * Math.exp(-data.column.length / 3));

  const getColumnWidth = () => {
    if (data.column.length * data.column.length < 20)
      return optimalColumnWidthPercent;

    return '80%';
  };

  const titleStyle: ApexChartsTitleStyle = {
    fontSize: '10px',
    fontWeight: 'bold',
  };

  const xCatFormatted = xCategories[0].map((_, colIndex) =>
    xCategories.map((row) => row[colIndex]),
  );

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: { enabled: false },
      animations: {
        enabled: false,
      },
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
    },
    grid: {
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: [barColor],
    plotOptions: {
      bar: {
        columnWidth: getColumnWidth(),
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#40A9FF', '#f62225'],
      },
      textAnchor: 'middle',
      formatter: (val) => {
        if (String(val).length > 3) return `${String(val).slice(0, 3)}..`;

        return String(val);
      },
    },
    markers: {
      size: 4,
      strokeColors: lineColor,
      strokeWidth: 2,
      colors: ['white'],
    },
    stroke: {
      curve: 'straight',
      width: [0, data.line.length === 1 ? 0 : 2],
      colors: [lineColor],
    },
    ...(xAxisTitle
      ? {
          xaxis: {
            categories: xCatFormatted,
            axisTicks: { show: false },
            labels: {
              style: { fontWeight: 500, fontSize: '10px' },
              hideOverlappingLabels: false,
              trim: true,
              minHeight: 100,
              maxHeight: 150,
            },
            ...(xUnit
              ? {
                  title: {
                    text: xAxisTitle,
                    style: titleStyle,
                  },
                }
              : {}),
            tooltip: {
              enabled: true,
              formatter(
                _,
                {
                  dataPointIndex,
                  w,
                }: {
                  dataPointIndex: number;
                  w: { globals: { categoryLabels: [] } };
                },
              ) {
                const categories = w.globals.categoryLabels[
                  dataPointIndex
                ] as [];

                if (!categories) return '';

                return categories.join('</br>');
              },
            },
          },
        }
      : {}),
    ...(yAxisTitle
      ? {
          yaxis: [
            {
              forceNiceScale: true,
              labels: {
                style: { fontWeight: 500, fontSize: '10px', colors: barColor },
              },
              ...(yUnit
                ? {
                    title: {
                      text: `(${yAxisTitle})`,
                      style: titleStyle,
                    },
                  }
                : {}),
            },
            {
              opposite: true,
              forceNiceScale: true,
              labels: {
                style: { fontWeight: 500, fontSize: '10px', colors: lineColor },
              },
              ...(yUnit2
                ? {
                    title: {
                      text: `(${yAxisTitle2})`,
                      style: titleStyle,
                    },
                  }
                : {}),
            },
          ],
        }
      : {}),

    fill: {
      colors: [barColor],
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        if (
          series.length === 0 ||
          seriesIndex === null ||
          dataPointIndex === null
        )
          return null;

        const dataLabel = series[seriesIndex][dataPointIndex];

        return dataLabel;
      },
    },
    ...(line !== undefined && !yAxisTitle2
      ? {
          annotations: {
            yaxis: [
              {
                y: line,
                borderColor: '#F5222D',
                borderWidth: 3,
                strokeDashArray: 0,
                label: {
                  borderColor: 'transparent',
                  style: {
                    color: lineDirection !== '=' ? '#F5222D' : 'transparent',
                    background: 'transparent',
                  },
                  text: lineDirection !== '=' ? '╱ ╱ ╱' : '_',
                  offsetY:
                    lineDirection === '>' || lineDirection === '>=' ? 0 : 12,
                },
              },
            ],
          },
        }
      : {}),
  };

  const series: ApexOptions['series'] = [
    {
      type: 'column',
      data: data.column,
    },
    {
      type: 'line',
      data: data.line,
    },
  ];

  return <Chart options={options} series={series} type="line" />;
}

export default ParetoChart;
