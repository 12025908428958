export const vn = {
  errors: {
    exist: {
      email: 'Người dùng với email đó đã tồn tại',
      employee_id: 'Người dùng với mã nhân viên đó đã tồn tại',
      group_name: 'Nhóm dây chuyền này đã tồn tại trong hệ thống',
      group_name_en: 'Nhóm dây chuyền này đã tồn tại trong hệ thống',
      line_name: 'Thuật ngữ này đã có trong hệ thống',
      line_name_en: 'Thuật ngữ này đã có trong hệ thống',
      process_equipment_name: 'Thuật ngữ này đã có trong hệ thống',
      process_equipment_name_en: 'Thuật ngữ này đã có trong hệ thống',
      product_id: 'Mã sản phẩm này đã có trong hệ thống',
      product_line_name: 'Dây chuyền sản phẩm này đã tồn tại trong hệ thống',
      product_line_name_en: 'Dây chuyền sản phẩm này đã tồn tại trong hệ thống',
      xr_name: 'Thuật ngữ này đã có trong hệ thống',
      xr_name_en: 'Thuật ngữ này đã có trong hệ thống',
      conversation_name: 'Hội thoại với tên đó đã tồn tại',
    },
    not_exist: {
      email: 'Email không tồn tại',
    },
  },
  date: 'Ngày',
  planned_down_loss_percentage: 'Mất mát trong kế hoạch',
  planned_performance_losses: 'Mất mát hiệu suất trong kế hoạch',
  line_name: 'Dây chuyền',
  oee_percentage: 'Hiệu suất thực tế',
  business_date: 'Ngày sản xuất',
  total_of_change_point: 'Số lượng điểm thay đổi',
  start_of_week: 'Bắt đầu tuần',
  end_of_week: 'Kết thúc tuần',
  point_of_change_detail: 'Điểm thay đổi chi tiết',
  product_amount_percentage_by_date: 'Tỷ lệ sản lượng theo ngày',
  planned_down_loss_percentage_by_date: 'Mất mát trong kế hoạch theo ngày',
  unplanned_down_loss_percentage_by_date: 'Mất mát ngoài kế hoạch theo ngày',
  time: 'Ngày',
  working_day: 'Ngày',
  process: 'Công đoạn',
  equipment: 'Thiết bị',
  XR: 'Hạng mục quản lý',
  No: 'STT',
  part_number: 'Mã sản phẩm',
  lost_group: 'Mất mát trong / ngoài kế hoạch',
  shift_name: 'Ca',
  current_xbar: 'Giá trị Xbar (kiểm tra) hiện tại',
  previous_xbar: 'Giá trị Xbar (kiểm tra) tháng trước',
  current_xbar_avg: 'Giá trị trung bình Xbar (kiểm tra) hiện tại',
  previous_xbar_avg: 'Giá trị trung bình Xbar (kiểm tra) tháng trước',
  procedure: 'Công đoạn',
  this_month_cp: 'CP tháng này',
  'this month': 'This month',
  'previous month': 'Previous month',
  action_line_specification: 'Quy cách Action line',
  this_month_cpk: 'CPK tháng này',
  this_month_avg: 'AVG tháng này',
  this_month_standard_deviation: 'Độ lệch chuẩn tháng này',
  last_month_avg: 'AVG tháng trước',
  last_month_standard_deviation: 'Độ lệch chuẩn tháng trước',
  last_month_cp: 'CP tháng trước',
  last_month_cpk: 'CPK tháng trước',
  scrap_type: 'Loại phế phẩm',
  avg: 'Trung bình',
  avg_performance_by_number: 'Hiệu suất',
  lot: 'Lot',
  scrap_category: 'Phế phẩm CD / LK / KT',
  total_scrap_amount: 'Số lượng phế phẩm',
  total_scrap_rate_target: 'Tỉ lệ phế phẩm',
  total_scrap_rate: 'Tỉ lệ phế phẩm',
  scrap_rate: 'Tỉ lệ phế phẩm',
  measurement_count: 'Số lần đo',
  target_percentage_by_date: 'Hiệu suất mục tiêu',
  cause_name: 'Nguyên nhân',
  'oee_percentage - target_percentage':
    'Hiệu suất thực tế - Hiệu suất mục tiêu',
  'oee_percentage_by_date - target_percentage':
    'Hiệu suất thực tế - Hiệu suất mục tiêu',
  'actual_production_amount - target_production_amount':
    'Sản lượng thực tế - Sản lượng mục tiêu',
  node_count: 'Số node',
  xbar: 'Giá trị Xbar (Giá trị kiểm tra)',
  rs: 'Giá trị Rs (Độ biến động so với giá trị trung bình)',
  xbar_avg: 'Giá trị Xbar (Kiểm tra) trung bình',
  cp: 'CP',
  pcs: 'cái',

  'This month': 'Tháng này',
  'Previous month': 'Tháng trước',
  'Last month': 'Tháng trước',

  'This week': 'Tuần này',
  'Previous week': 'Tuần trước',
  'Last week': 'Tuần trước',

  Today: 'Hôm nay',
  Yesterday: 'Hôm qua',

  'This year': 'Năm nay',
  'Previous year': 'Năm trước',
  'Last year': 'Năm trước',

  min: 'phút',
  day_time: 'ngày',
  Process: 'Công đoạn',
  cpk: 'CPK',
  change_point: 'điểm thay đổi',
  count: 'lần',
  standard_deviation: 'Độ lệch chuẩn',
  is_xr_meet_expect: 'Hạng mục quản lý có đáp ứng kì vọng không?',
  batch: 'vụ',
  staging: 'Công đoạn',
  category: 'Hạng mục',
  time_window_end_time: 'Khung thời gian',
  product_code: 'Mã sản phẩm',
  target_production_amount: 'Sản lượng mục tiêu',
  actual_production_amount: 'Sản lượng thực tế',
  loading_time: 'Thời gian thực tế chạy ',
  oee: 'Tỉ lệ hiệu suất',
  'deficit production amount': 'Số lượng sản phẩm bị thiếu',
  total: 'Tổng',
  planned_down_loss: 'Mất mát trong kế hoạch',
  unplanned_down_loss: 'Mất mát ngoài kế hoạch',
  target_production_amount_sum: 'Sản lượng mục tiêu theo lũy kế',
  actual_production_amount_sum: 'Sản lượng thực tế theo lũy kế',
  ot_time: 'Overtime',
  planned_production_amount: 'Sản lượng kế hoạch',
  performance_minutes: 'Mất mát phút',
  performance_batches: 'Mất mát vụ',
  performance_percentage: 'Mất mát %',
  net_production_amount: 'Sản lượng thực tế',
  cycle_time: 'Cycle time',
  target_performance: 'Hiệu suất mục tiêu',
  target_line_performance: 'Hiệu suất mục tiêu',
  line_target_performance: 'Hiệu suất mục tiêu',
  'performance of line': 'Hiệu suất',
  performance: 'Hiệu suất',
  January: 'Tháng 1',
  February: 'Tháng 2',
  March: 'Tháng 3',
  April: 'Tháng 4',
  May: 'Tháng 5',
  June: 'Tháng 6',
  July: 'Tháng 7',
  August: 'Tháng 8',
  September: 'Tháng 9',
  October: 'Tháng 10',
  November: 'Tháng 11',
  December: 'Tháng 12',
  item: 'Hạng mục quản lý',
  factory_performance: 'Hiệu suất',
  scrap_amount: 'Số lượng phế phẩm',
  line_performance: 'Hiệu suất',
  planned_performance_loss: 'Mất mát hiệu suất theo kế hoạch',
  planned_performance_loss_percentage:
    'Phần trăm mất mát hiệu suất theo kế hoạch',
  unplanned_performance_loss: 'Mất mát hiệu suất ngoài kế hoạch',
  unplanned_performance_loss_percentage:
    'Phần trăm mất mát hiệu suất ngoài kế hoạch',
  change_point_of: 'các điểm thay đổi',
  loading_time_minutes: 'Phút',
  target_cycle_time: 'Thời gian chu kỳ mục tiêu',
  target_percentage: 'Hiệu suất mục tiêu',
  oee_percentage_by_date: 'Hiệu suất thực tế',
  unplanned_down_loss_percentage: 'Tỷ lệ mất mát ngoài kế hoạch',
  exclude_loss_percentage: 'Tỷ lệ loại trừ mất mát',
  other_loss_percentage: 'Tỷ lệ mất mát khác',
  unknown_percentage: 'Tỷ lệ không rõ',
  data_datetime: 'Thời gian dữ liệu',
  'product_amount/actual_production_amount_by_line': 'Tỷ lệ sản lượng',
  product_amount_percentage: 'Tỷ lệ sản lượng',
  product_amount: 'Sản lượng',
  error_type: 'Điểm thay đổi',
  overtime: 'Overtime',
  day: 'Thời gian',
  datetime: 'Thời gian',
  line: 'Dây chuyền',
  production_rate: 'Tỉ lệ sản lượng',
  product_number: 'Mã sản phẩm',
  production_amount: 'Sản lượng',
  Monday: 'Thứ 2',
  Tuesday: 'Thứ 3',
  Wednesday: 'Thứ 4',
  Thursday: 'Thứ 5',
  Friday: 'Thứ 6',
  Saturday: 'Thứ 7',
  Sunday: 'chủ nhật',
  'Update password successfully': 'Cập nhật mật khẩu thành công',
  'Update password unsuccessfully': 'Cập nhật mật khẩu không thành công',
  'The importing file does not match the system’s requirements':
    'Tệp nhập không phù hợp với yêu cầu của hệ thống',
  'Choose line': 'Chọn dây chuyền',
  'Choose process': 'chọn công đoạn',
  business_month: 'Tháng sản xuất',
  business_year: 'Năm sản xuất',
  'New X-R': 'Thêm X-R',
  'Control chart name': 'Hạng mục quản lý',
  'Control chart name ENG': 'Hạng mục quản lý ENG',
  'Control chart name VIE': 'Hạng mục quản lý VIE',
  'Product name': 'Tên sản phẩm',
  'Product line': 'Dây chuyền sản phẩm',
  'Product line ENG': 'Dây chuyền sản phẩm ENG',
  'Product line VIE': 'Dây chuyền sản phẩm VIE',
  'Product ID': 'Mã sản phẩm',
  'Enter product ID': 'Nhập mã sản phẩm',
  'New product line': 'Thêm dây chuyền sản phẩm',
  'Enter product line': 'Nhập dây chuyền sản phẩm',
  'Choose product line': 'Chọn dây chuyền sản phẩm',
  'Add new product line': 'Thêm dây chuyền sản phẩm mới',
  'Terminology updated successfully': 'Cập nhật thuật ngữ thành công',
  'Terminology updated unsuccessfully': 'Cập nhật thuật ngữ không thành công',
  '{{resource}}(s) deleted successfully': 'Xóa {{resource}} thành công',
  '{{resource}} {{name}} deleted successfully':
    'Xóa {{resource}} {{name}} thành công',
  '{{resource}}(s) deleted unsuccessfully': 'Xóa {{resource}} không thành công',
  '{{resource}} {{name}} deleted unsuccessfully':
    'Xóa {{resource}} {{name}} không thành công',
  'We apologize, but the system encountered an issue and could not display the chart at this time. Please try again later or contact support if the problem persists.':
    'Rất tiếc, hệ thống đã gặp sự cố và không thể hiển thị biểu đồ tại thời điểm này. Vui lòng thử lại sau hoặc liên hệ với bộ phận hỗ trợ nếu vấn đề vẫn tiếp tục xảy ra.',
  'Sorry, the chart you selected is currently unavailable. Please choose one of the charts above':
    'Xin lỗi biểu đồ bạn chọn hiện không khả dụng bạn vui lòng chọn một trong các biểu đồ trên',
  'Update email successfully': 'Cập nhật địa chỉ Email thành công',
  'Update email unsuccessfully': 'Cập nhật địa chỉ Email không thành công',
  'Not Delivered': 'Chưa được gửi',
  Login: 'Đăng nhập',
  Line: 'Dây chuyền',
  'Choose line group': 'Chọn nhóm dây chuyền',
  'Process terminology ENG': 'Thuật ngữ công đoạn ENG',
  'Process terminology VIE': 'Thuật ngữ công đoạn VIE',
  'Terminology created successfully': 'Tạo thuật ngữ thành công',
  'Terminology created unsuccessfully': 'Tạo thuật ngữ không thành công',
  'Line group': 'Nhóm dây chuyền',
  'Line group ENG': 'Nhóm dây chuyền ENG',
  'Line group VIE': 'Nhóm dây chuyền VIE',
  'Welcome to chatbot': 'Xin chào',
  'Everything in control with DensoGPT':
    'Đồng hành và kiểm soát cùng với DensoGPT',
  'Sign up': 'Đăng ký',
  'Select file': 'Chọn tệp',
  'Drag and drop data file or select from device':
    'Kéo và thả tệp dữ liệu hoặc chọn từ thiết bị',
  'New process': 'Thêm công đoạn',
  'New product': 'Thêm sản phẩm',
  'Download template': 'Tải xuống mẫu',
  'Choose another file': 'Chọn tệp khác',
  'Created by': 'Người tạo',
  'Add manual': 'Thêm thủ công',
  'Choose group': 'Chọn nhóm',
  'Line terminology': 'Line terminology',
  'Line terminology ENG': 'Thuật ngữ dây chuyền ENG',
  'Line terminology VIE': 'Thuật ngữ dây chuyền VIE',
  'Enter terminology': 'Nhập thuật ngữ',
  'No matching item': 'Không có kết quả phù hợp',
  'New line': 'Thêm dây chuyền',
  'This field is required': 'Trường này là bắt buộc',
  'New group': 'Thêm nhóm',
  'Add new group': 'Thêm nhóm mới',
  'Group created unsuccessfully': 'Tạo nhóm không thành công',
  'Group created successfully': 'Tạo nhóm thành công',
  'Enter group name': 'Nhập tên nhóm',
  'Group name': 'Tên nhóm',
  'Created time': 'Thời gian tạo',
  'Terms and Conditions': 'Điều khoản và Điều kiện',
  Upload: 'Tải lên',
  Importing: 'Đang nhập',
  'Hi there! Do you know how to use the chatbot? If not, you can view the instructions':
    'Chào bạn! Bạn đã biết cách sử dụng chatbot chưa? Nếu chưa, bạn có thể xem hướng dẫn',
  Here: 'Tại đây',
  Continue: 'Tiếp tục',
  'Update personal information': 'Cập nhật thông tin cá nhân',
  'First name': 'Tên',
  'Chatbot user guide': 'Hướng dẫn sử dụng chatbot',
  'Please update the guideline content for both the English and Vietnamese versions':
    'Vui lòng cập nhật nội dung hướng dẫn cho cả bản tiếng Anh và tiếng Việt',
  'Update guideline content': 'Cập nhật nội dung hướng dẫn',
  'Guideline content': 'Nội dung hướng dẫn',
  'The chatbot can assist you quickly with the following tasks. If you encounter any issues while using the chatbot, please contact the support team for assistance.':
    'Chatbot có thể hỗ trợ bạn nhanh chóng thông qua các tác vụ dưới đây. Nếu bạn gặp bất kỳ vấn đề nào khi sử dụng chatbot, vui lòng liên hệ với bộ phận hỗ trợ để được trợ giúp.',
  Close: 'Đóng',
  'Terminologies imported successfully': 'Nhập thuật ngữ thành công',
  'Terminologies imported unsuccessfully': 'Nhập thuật ngữ không thành công',
  'Last name': 'Họ',
  'Current password': 'Mật khẩu hiện tại',
  'Retype new password': 'Nhập lại mật khẩu mới',
  'Cancel importing data': 'Hủy nhập dữ liệu',
  'Skip duplicated data': 'Bỏ qua dữ liệu trùng lặp',
  'Upload file excel': 'Tải lên tệp excel',
  'Importing data': 'Nhập dữ liệu',
  'Please update the upload file to .xlxs or csv format before uploading data. Maximum upload file size is 5MB':
    'Vui lòng cập nhật tệp tải lên sang định dạng .xlsx hoặc csv trước khi nhập dữ liệu. Kích thước tệp tải lên tối đa là 5MB',
  'All importing data from file {{fileName}} update will not be saved into system. Are you sure to continue cancel the process?':
    'Tất cả dữ liệu nhập từ tệp {{fileName}} sẽ không được lưu vào hệ thống. Bạn có chắc chắn muốn tiếp tục hủy quá trình này không?',
  'Product of Denso Vietnam & Fpt Software':
    'Sản phẩm hợp tác giữa Denso Việt Nam & Phần mềm FPT',
  'Create account with your email and Denso employee ID':
    'Tạo tài khoản bằng email và mã nhân viên Denso của bạn',
  'Enter first name': 'Nhập tên',
  'Enter last name': 'Nhập họ và tên đệm',
  'Enter email': 'Nhập email',
  'Enter password': 'Nhập mật khẩu',
  'Enter employee ID': 'Nhập mã nhân viên',
  'Retype password': 'Nhập lại mật khẩu',
  'Forgot Password': 'Quên mật khẩu',
  'Do not have an account?': 'Chưa có tài khoản?',
  'Create account': 'Tạo tài khoản',
  'Back to Login': 'Quay lại Đăng nhập',
  'User management': 'Quản lý thành viên',
  'Terminology management': 'Quản lý thuật ngữ',
  'Request management': 'Quản lý yêu cầu',
  Search: 'Tìm kiếm',
  Role: 'Vai trò',
  Total: 'Tổng',
  'Add new': 'Thêm thành viên',
  Delete: 'Xóa',
  Admin: 'Admin',
  User: 'User',
  Account: 'Tài khoản',
  Name: 'Tên',
  'Employee ID': 'Mã nhân viên',
  Action: 'Action',
  Approve: 'Chấp thuận',
  Password: 'Mật khẩu',
  Product: 'Sản phẩm',
  'Part number': 'Mã sản phẩm',
  'Email address': 'Địa chỉ Email',
  'Incorrect format': 'Định dạng không đúng',
  'I agree to': 'Tôi đồng ý với',
  'Denso GPT Terms & Private Policy':
    'Điều khoản & Chính sách Bảo mật của Denso GPT',
  'Login successfully': 'Đăng nhập thành công.',
  'Invalid email/password': 'Email/Mật khẩu không đúng.',
  'Please input email field!': 'Hãy bổ sung trường thông tin email!',
  'Please input password field!': 'Hãy bổ sung trường thông tin mật khẩu!',
  'Please input employee ID field!':
    'Hãy bổ sung trường thông tin mã nhân viên!',
  'Please retype your password!':
    'Hãy bổ sung trường thông tin nhập lại mật khẩu!',
  'Please input first name!': 'Hãy bổ sung trường thông tin tên!',
  'Please input last name!': 'Hãy bổ sung trường thông tin họ và tên đệm!',
  'Should accept agreement':
    'Hãy đọc và đồng ý với Điều khoản & Chính sách Bảo mật của Denso GPT!',
  'Invalid email field': 'Trường email không hợp lệ!',
  'Invalid password field': 'Trường mật khẩu không hợp lệ!',
  'Please enter email you registered your account, we will send an OTP your email address.':
    'Vui lòng nhập thông tin email bạn đã sử dụng để đăng ký tài khoản, chúng tôi sẽ gửi mã OTP đến địa chỉ email của bạn để xác nhận.',
  'Send OTP': 'Gửi OTP',
  'Email sent successfully.': 'Đã gửi email thành công ',
  'Failed to sent email. Please try again.':
    'Gửi email thất bại. vui lòng thử lại',
  'Sign up request successfully!': 'Yêu cầu đăng ký thành công!',
  'After we will send confirmation to your email address within 24h, following confirmation you can log into the system':
    'Trong vòng 24h sau khi chúng tôi hoàn tất xác nhận tài khoản, chúng tôi sẽ gửi xác nhận đến địa chỉ email của bạn. Sau đó bạn có thể sử dụng tài khoản để đăng nhập vào hệ thống',
  'If you need a support, please': 'Nếu bạn cần hỗ trợ, vui lòng',
  'Contact us': 'Liên hệ với chúng tôi',
  'The new password that you entered do not match!': 'Mật khẩu không khớp!',
  All: 'Tất cả',
  'Edit terminology': 'Cập nhật thuật ngữ',
  'Are you sure to delete all selected {{resource}}?':
    'Bạn có chắc muốn xóa tất cả {{resource}} bạn đã chọn?',
  'Are you sure to delete': 'Bạn có chắc muốn xóa  <strong>{{name}}</strong>?',
  'Are you sure to delete all selected requests?':
    'Bạn có chắc muốn xóa tất cả yêu cầu bạn đã chọn?',
  'Delete request': 'Xóa yêu cầu',
  'Delete user': 'Xóa thành viên',
  Reset: 'Reset',
  'Update user successfully': 'Cập nhật thông tin cá nhân thành công',
  'Update user unsuccessfully': 'Cập nhật thông tin cá nhân không thành công',
  'Reset password': 'Đặt lại mật khẩu',
  'New password': 'Mật khẩu mới',
  'The old password is incorrect': 'Mật khẩu cũ không chính xác',
  'Are you sure to reset password of user':
    'Bạn có muốn đặt lại mật khẩu tài khoản <strong>{{name}}</strong>?',
  'First Name': 'Tên',
  'Last Name': 'Họ và tên đệm',
  'Are you sure to delete user':
    'Bạn có chắc muốn xóa thành viên <strong>{{name}}</strong>?',
  'Are you sure to approve all selected requests?':
    'Bạn có chắc muốn kích hoạt tất cả tài khoản bạn đã chọn?',
  'Are you sure to delete all selected users?':
    'Bạn có chắc muốn xóa tất cả thành viên bạn đã chọn?',
  'Approve request': 'Kích hoạt',
  'Are you sure to approve user':
    'Bạn có chắc muốn kích hoạt tài khoản <strong>{{name}}</strong>?',
  Save: 'Lưu',
  'Save user successfully': 'Sửa tài khoản thành công',
  'Create user successfully': 'Tạo tài khoản thành công',
  'Delete successfully': 'Xóa thành công',
  'Delete unsuccessfully': 'Xóa không thành công',
  'Create user unsuccessfully': 'Tạo tài khoản không thành công',
  'Approve unsuccessfully': 'Kích hoạt tài khoản thất bại',
  'Approve successfully': 'Kích hoạt tài khoản thành công',
  'Create password unsuccessfully': 'Tạo mật khẩu không thành công',
  'Register successfully': 'Đăng ký thành công',
  'Register unsuccessfully': 'Đăng ký không thành công',
  'Reset password successfully': 'Thay đổi mật khẩu thành công',
  Rename: 'Thay đổi tên',
  'Type Message...': 'Nhập tin nhắn...',
  'Are you sure to delete this conversation?':
    'Bạn có chắc chắn muốn xóa cuộc trò chuyện này không?',
  'Delete conversation successfully': 'Xóa cuộc trò chuyện thành công',
  'Delete conversation unsuccessfully': 'Xóa cuộc trò chuyện không thành công',
  'Rename conversation successfully': 'Đổi tên cuộc trò chuyện thành công',
  'Rename conversation unsuccessfully':
    'Đổi tên cuộc trò chuyện không thành công',
  'Delete conversation': 'Xóa cuộc trò chuyện',
  Cancel: 'Hủy',
  Confirm: 'Xác nhận',
  'Send test email': 'Kiểm tra email',
  'Please enter the OTP sent to your email {{email}} to reset password':
    'Vui lòng nhập mã OTP được gửi tới tài khoản email của bạn {{email}} để tiến hành thay đổi mật khẩu',
  Resend: 'Gửi lại',
  Group: 'Nhóm',
  'Update email': 'Cập nhật địa chỉ email',
  'Failed to send email. Please recheck the email address and password':
    'Gửi email thất bại. Vui lòng kiểm tra lại địa chỉ email và mật khẩu',
  'Failed to send email. Please try again later':
    'Gửi email thất bại. Vui lòng thử lại sau.',
  'New terminology': 'Thuật ngữ mới',
  'Are you sure to save the update?': 'Bạn có chắc muốn lại thay đổi?',
  'You can check the mailbox': 'Vui lòng kiểm tra hòm thư',
  'The test email was sent successfully!': 'Email thử nghiệm đã được gửi!',
  'The test email was sent unsuccessfully!':
    'Email thử nghiệm đã được gửi thất bại do lỗi!',
  'Can not connect to SMTP server': 'Kết nối tới máy chủ thất bại',
  'The inputed password does not match requirements':
    'Mật khẩu nhập vào không đáp ứng các yêu cầu',
  'OTP after': 'mã OTP sau',
  'Please enter your new password': 'Nhập lại mật khẩu mới',
  'Incorrect OTP, please recheck it':
    'Mã OTP không chính xác, vui lòng kiểm tra lại',
  'Update system email': 'Cập nhật email hệ thống',
  'Personal information': 'Thông tin cá nhân',
  'Log out': 'Đăng xuất',
  'Add new user': 'Thêm thành viên mới',
  'Edit user information': 'Chỉnh sửa thông tin thành viên',
  'Password must be at least 8 characters long.':
    'Mật khẩu phải có ít nhất 8 ký tự.',
  'The {{name}} password has been reset as below:':
    'Mật khẩu của người dùng <strong>{{name}}</strong> đã được đặt lại như sau:',
  'It must include at least one uppercase letter, one lowercase letter, and one number.':
    'Phải bao gồm ít nhất một ký tự chữ hoa, một ký tự chữ thường và một ký tự số.',
  'Everything now in your control with DensoGPT':
    'Cùng đồng hành làm chủ tất cả với DensoGPT',
  // eslint-disable-next-line quotes
  'Lets start!': 'Chúng ta bắt đầu thôi!',
  '+ New chat': '+ Trò chuyện mới',
  'Bar chart': 'Biểu đồ Cột',
  'Line chart': 'Biểu đồ Đường',
  Table: 'Bảng dữ liệu',
  'Pie chart': 'Biểu đồ Tròn',
  'Pareto chart': 'Biểu đồ Pareto',
  Ownership: 'Quyền sở hữu',
  'Information Security': 'Bảo mật thông tin',
  'Usage Rights': 'Quyền sử dụng',
  'Company name': 'Tên công ty',
  Address: 'Địa chỉ',
  Department: 'Phòng ban',
  Section: 'Bộ phận',
  'Contact Information': 'Thông tin liên lạc',
  'Working hours': 'Giờ làm việc',
  'Phone number': 'Số điện thoại',

  'Publish guideline successfully':
    'Lưu bản chính thức hướng dẫn sử dụng thành công',
  'Publish guideline unsuccessfully':
    'Lưu bản chính thức hướng dẫn sử dụng không thành công',
  'Save guideline as draft successfully':
    'Lưu bản nháp hướng dẫn sử dụng thành công',
  'Save guideline as draft unsuccessfully':
    'Lưu bản nháp hướng dẫn sử dụng không thành công',
  'Save draft confirmation': 'Xác nhận lưu bản nháp',
  'Publish confirmation': 'Xác nhận lưu bản chính thức',
  'Do you want to save this draft? You can continue editing later.':
    'Bạn có muốn lưu bản nháp này không? Bạn có thể tiếp tục chỉnh sửa sau.',
  'Are you sure you want to save this content and publish it? Once published, the content will be applied across the system.':
    'Bạn có chắc chắn muốn lưu nội dung này và xuất bản chính thức không? Sau khi xuất bản, nội dung sẽ áp dụng trên toàn hệ thống.',

  'DENSO MANUFACTURING VIETNAM CO., LTD': 'DENSO MANUFACTURING VIETNAM CO.,LTD',
  'Monday to Friday, 8:10 AM ~ 5:15 PM': 'Monday to Friday 8:10~17:15',
  'Plot E-1, Thang Long Industrial Park, Kim Chung Commune, Dong Anh District, Hanoi, Vietnam':
    'Lô E-1, Khu công nghiệp Thăng Long, Xã Kim Chung, Huyện Đông Anh, Hà Nội, Việt Nam',
  'This software and all related documents are the property of the Production Engineering Department and are protected by intellectual property rights. You are not allowed to copy, distribute, rent, sublet, sell, transfer, or modify the software or any related documents without written permission from the Production Engineering Department.':
    'Phần mềm này và tất cả các tài liệu liên quan là tài sản của bộ phận kỹ thuật sản xuất và được bảo vệ bởi các quyền sở hữu trí tuệ. Bạn không được sao chép, phân phối, thuê, cho thuê lại, bán, chuyển giao hoặc sửa đổi phần mềm hoặc bất kỳ tài liệu nào liên quan mà không có sự cho phép bằng văn bản từ bộ phận kỹ thuật sản xuất.',
  'You commit to keeping confidential and secure all login information, account passwords, and any information provided directly or indirectly by the Denso GPT software. This information is considered confidential and proprietary to Denso Manufacturing Vietnam and is only allowed for internal use within the company. You are responsible for any activities that occur on your account. If you detect any suspicious activity or unauthorized access to your account, you must immediately notify DMVN PE.':
    'Bạn cam kết giữ bí mật và bảo mật tất cả thông tin đăng nhập, mật khẩu của tài khoản và các thông tin được cung cấp trực tiếp hoặc gián tiếp từ phần mềm Denso GPT. Các thông tin này thuộc về thông tin mật của công ty Denso Manufacturing Việt Nam và chỉ cho phép sử dụng nội bộ trong công ty. Bạn có trách nhiệm chịu trách nhiệm về bất kỳ hoạt động nào xảy ra trên tài khoản của mình. Nếu phát hiện bất kỳ hoạt động nghi ngờ hoặc truy cập trái phép vào tài khoản, bạn phải thông báo ngay lập tức cho DMVN PE.',
  'By registering an account, you are granted the right to use this software for your personal or work-related purposes within the permitted scope. You are not allowed to use the software for any commercial or illegal purposes.':
    'Bằng cách đăng ký tài khoản, bạn được cấp quyền sử dụng phần mềm này cho mục đích sử dụng cá nhân hoặc công việc của mình trong phạm vi được phép. Bạn không được phép sử dụng phần mềm cho bất kỳ mục đích thương mại hoặc phi pháp nào.',
  'Welcome to the registration for using the Denso GPT software by the Production Engineering Department of Denso Manufacturing Vietnam (hereinafter referred to as the Production Engineering Department). Before continuing to use the software, you need to read and understand the following Terms and Conditions. Using this software means that you accept and comply with the following terms and conditions:':
    'Chào mừng bạn đến với việc đăng ký tài khoản sử dụng phần mềm Denso GPT của bộ phận kỹ thuật sản xuất công ty Denso Manufacturing Việt Nam (sau đây gọi tắt là bộ phận kỹ thuật sản xuất). Trước khi tiếp tục sử dụng phần mềm, bạn cần đọc và hiểu rõ các Điều khoản và Điều kiện sau đây. Việc sử dụng phần mềm này đồng nghĩa với việc bạn chấp nhận và tuân thủ các điều khoản và điều kiện dưới đây:',
};
