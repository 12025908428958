// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable promise/no-promise-in-callback */
import axios from 'axios';

import type { LoginResDTO } from 'types';
import { useAuthStore } from '../stores';

const baseURL = process.env.REACT_APP_API_URL;
const contentType = 'application/json';
const accessControlAllowCredentials = true;

let isRefreshing = false;
let refreshTokenPromise: Promise<unknown> | null = null;

export const axiosNoAuthInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': contentType,
    'Access-Control-Allow-Credentials': accessControlAllowCredentials,
  },
});

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': contentType,
    'Access-Control-Allow-Credentials': accessControlAllowCredentials,
  },
});

const getTokens = () => ({
  refreshToken: useAuthStore.getState()?.user?.refresh,
  accessToken: useAuthStore.getState()?.user?.access,
});

const setAccessToken = (access: string) =>
  useAuthStore.setState((state) => ({
    ...state,
    user: { ...(state.user as LoginResDTO), access },
  }));

const refreshTokens = (token: string) =>
  axiosInstance.post<never, { access: string }>('/api/token/refresh/', {
    refresh: token,
  });

const logoutUser = () => {
  const userStore = useAuthStore.getState();

  userStore.resetUser();
};

axiosNoAuthInstance.interceptors.response.use(
  (response) => response.data as never,
);

axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken } = getTokens();

    if (accessToken)
      config.headers.Authorization = accessToken && `Bearer ${accessToken}`;

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response.data as never,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const { refreshToken } = getTokens();

          if (!refreshToken) {
            logoutUser();

            return await Promise.reject(error);
          }

          const { access } = await refreshTokens(refreshToken);

          setAccessToken(access);

          originalRequest.headers.Authorization = `Bearer ${access}`;

          isRefreshing = false;
          refreshTokenPromise = null;

          return await axiosInstance(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          refreshTokenPromise = null;
          window.location.href = '/login';
          logoutUser();

          return Promise.reject(refreshError);
        }
      } else {
        await refreshTokenPromise;

        const { accessToken } = getTokens();

        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        return axiosInstance(originalRequest);
      }
    }

    // if (error.response?.status === 500) console.log(error);

    return Promise.reject(error?.response?.data);
  },
);
