import { useEffect, useMemo, useState } from 'react';
import { Flex, Spin, Typography } from 'antd';

import ChatBotAvatar from 'assets/icons/chatbot-avatar.png';

import {
  type IChartContent,
  type IChartType,
  type ITableContent,
  type LanguageType,
} from 'types';
import { useChatStore } from 'stores/useChatStore';
import { CustomSegmented } from 'components';

import { capitalize } from 'utils';
import type { IMetadata } from 'types/models';
import { TableData } from '../../TableData';
import Charts from '../../Charts';
import {
  isChartDrawable,
  isTableDrawable,
  determineChartTypes,
  determineDefaultChartType,
  mapResponseToChartData,
} from '../utils';
import { MessageWrapper } from '../MessageWrapper';
import { ChartWrapper, CustomPagination } from './style';
import { chartOptions } from './constant';

interface ChartMessageProps {
  metaData: IMetadata;
  language?: LanguageType;
  chartError?: string;
  defaultChart?: IChartType;
  defaultTimeLevel: string;
  timeLevelOptions?: string[];
  textSQL?: string;
}

export function ChartMessage({
  metaData,
  language,
  chartError,
  defaultChart = 'Table',
  defaultTimeLevel,
  timeLevelOptions,
  textSQL,
}: ChartMessageProps) {
  const [chartType, setChartType] = useState<IChartType>(defaultChart);
  const [timeLevel, setTimeLevel] = useState<string>(defaultTimeLevel);

  const [isLoading] = useState(false);

  const [chartContent, setChartContent] = useState<IChartContent>();
  const [tableContent, setTableContent] = useState<ITableContent>();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const { setChartError } = useChatStore();

  useEffect(() => {
    if (!chartContent) return;

    const defaultChartFromMap = determineDefaultChartType(chartContent);
    const drawableCharts = determineChartTypes(chartContent);

    if (
      defaultChartFromMap === null ||
      !drawableCharts.includes(defaultChart) ||
      !drawableCharts.includes(defaultChartFromMap)
    )
      setChartType('Table');
    else setChartType(defaultChartFromMap);
  }, [chartContent, defaultChart, timeLevel]);

  useEffect(() => {
    setTimeLevel(defaultTimeLevel);
  }, [defaultTimeLevel]);

  useEffect(() => {
    const curChartContent = metaData[timeLevel].chart_content;
    const curTableContent = metaData[timeLevel].table_content;
    const curChartSQL = metaData[timeLevel].chart_sql;

    setChartContent(curChartContent);
    setTableContent(curTableContent);

    console.info({
      'Text SQL': textSQL,
      'Chart SQL': curChartSQL,
    });
  }, [metaData, chartType, timeLevel]);

  useEffect(() => {
    if (chartContent && !isChartDrawable(chartContent)) setChartType('Table');
  }, [chartContent, chartType]);

  const handleChartChange = (type: IChartType) => {
    setChartError('');
    setChartType(type);
  };

  const handleTimeLevelChange = (level: string) => {
    setTimeLevel(level);
  };

  const {
    xCat,
    data,
    min,
    max,
    xUnit,
    yUnit,
    pieData,
    yUnit2,
    line,
    lineDirection,
  } = useMemo(
    () => mapResponseToChartData(chartContent, pageIndex, pageSize),
    [pageIndex, pageSize, chartContent],
  );

  const getPageSizeOptions = (dataLength: number) => {
    const predefinedSizes = [5, 7, 10];

    if (dataLength >= 20 || dataLength < Math.min(...predefinedSizes))
      return predefinedSizes;

    if (!predefinedSizes.includes(dataLength)) predefinedSizes.push(dataLength);

    return predefinedSizes.sort((a, b) => a - b);
  };

  return (
    <Flex align="end" gap={8}>
      <img alt="avatar" src={ChatBotAvatar} />
      <MessageWrapper
        style={{
          position: 'relative',
          minWidth: 650,
          cursor: 'pointer',
        }}
      >
        <Spin spinning={isLoading}>
          <Typography.Text
            style={{
              fontSize: 16,
              lineHeight: '22px',
            }}
          >
            {chartContent && tableContent && isTableDrawable(tableContent) && (
              <ChartWrapper vertical gap={8}>
                <Flex gap={8} wrap>
                  {timeLevelOptions && timeLevelOptions?.length > 0 && (
                    <CustomSegmented
                      options={timeLevelOptions.map((item) => ({
                        value: item,
                        label: (
                          <span
                            style={{
                              color: item === timeLevel ? '#F5222D' : '#262626',
                              display: 'flex',
                              padding: 5,
                              fontWeight: 500,
                            }}
                          >
                            {capitalize(item)}
                          </span>
                        ),
                      }))}
                      defaultValue={timeLevel}
                      onChange={handleTimeLevelChange}
                    />
                  )}
                  <CustomSegmented
                    options={chartOptions
                      .filter((opt) =>
                        determineChartTypes(chartContent).includes(opt.value),
                      )
                      .map((item) => ({
                        value: item.value,
                        label: (
                          <span
                            style={{
                              fill:
                                item.value === chartType ? 'red' : '#595959',
                              display: 'flex',
                              padding: 5,
                            }}
                          >
                            {item.icon}
                          </span>
                        ),
                      }))}
                    defaultValue={chartType}
                    onChange={handleChartChange}
                  />
                </Flex>
                {chartType === 'Bar' && (
                  <Charts.Bar
                    data={data}
                    xCategories={xCat}
                    max={max}
                    xUnit={xUnit}
                    yUnit={yUnit}
                    yUnit2={yUnit2}
                    language={language}
                    line={line}
                    lineDirection={lineDirection}
                  />
                )}
                {chartType === 'Line' && (
                  <Charts.Line
                    data={data}
                    xCategories={xCat}
                    min={min}
                    max={max}
                    xUnit={xUnit}
                    yUnit={yUnit}
                    yUnit2={yUnit2}
                    language={language}
                  />
                )}
                {chartType === 'Pie' && (
                  <Charts.Pie data={pieData} categories={xCat[0]} />
                )}
                {chartType === 'Table' && (
                  <TableData language={language} data={tableContent.data} />
                )}
                {chartType === 'Pareto' && (
                  <Charts.Pareto
                    data={{ column: data[0], line: data[1] }}
                    xCategories={xCat}
                    min={min}
                    max={max}
                    xUnit={xUnit}
                    yUnit={yUnit}
                    yUnit2={yUnit2}
                    language={language}
                    line={line}
                    lineDirection={lineDirection}
                  />
                )}
                {chartType !== 'Table' && (
                  <CustomPagination
                    style={{ margin: 'auto' }}
                    size="small"
                    current={pageIndex}
                    pageSize={pageSize}
                    showLessItems
                    total={chartContent.data[0].length}
                    onChange={(page) => setPageIndex(page)}
                    onShowSizeChange={(_, size) => setPageSize(size)}
                    pageSizeOptions={getPageSizeOptions(
                      chartContent?.data[0].length,
                    )}
                    showSizeChanger={chartContent?.data[0].length > 5}
                  />
                )}
              </ChartWrapper>
            )}
          </Typography.Text>
          {chartError && (
            <Typography.Text
              type="danger"
              style={{
                fontSize: 12,
                position: 'absolute',
                top: '100%',
                left: 0,
              }}
            >
              {chartError}
            </Typography.Text>
          )}
        </Spin>
      </MessageWrapper>
    </Flex>
  );
}
