import type { FormProps, SelectProps } from 'antd';
import { Form, Input, message, Select, Spin, Typography } from 'antd';
import { CustomModal } from 'components';
import { useErrors, type ErrorType } from 'hooks/useErrors';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createTerminologyByType,
  getTerminologiesByType,
  getTerminologyByType,
  updateTerminologyByType,
} from 'services/terminologyManagement.service';
import type { IProcess, CreateXRReqDTO, ILine } from 'types';

interface AddEditXRModalProps {
  open: boolean;
  toggleOpen: (value: boolean) => void;
  refetch: () => Promise<void>;
  xrId?: number;
  resetSingleState?: () => void;
}

export function AddEditXRModal(props: AddEditXRModalProps) {
  const { open, toggleOpen, refetch, xrId, resetSingleState } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm<CreateXRReqDTO>();
  const { mapToFormErrors } = useErrors();

  const [lines, setLines] = useState<ILine[]>([]);
  const [processes, setProcesses] = useState<IProcess[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const isEdit = !!xrId;

  useEffect(() => {
    if (!xrId || isLoading || !isEdit || !open) return;
    setIsLoading(true);
    getTerminologyByType<'XR'>('XR', xrId)
      .then((res) => {
        setIsLoading(false);

        form.setFieldsValue(res);

        return res;
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [open, xrId]);

  const handleOk = () => {
    form.submit();
  };

  const handleFinish: FormProps['onFinish'] = async (
    values: CreateXRReqDTO,
  ) => {
    try {
      setIsLoading(true);

      if (isEdit && xrId) await updateTerminologyByType('XR', xrId, values);
      else await createTerminologyByType('XR', values);

      message.success({
        content: t(
          isEdit
            ? 'Terminology updated successfully'
            : 'Terminology created successfully',
        ),
      });

      setIsLoading(false);
      await refetch();
      toggleOpen(false);
    } catch (error) {
      setIsLoading(false);
      form.setFields(mapToFormErrors(error as ErrorType));
      message.error({
        content: t(
          isEdit
            ? 'Terminology updated unsuccessfully'
            : 'Terminology created unsuccessfully',
        ),
      });
    }
  };

  const handleClose = () => {
    resetSingleState?.();
    toggleOpen(false);
  };

  const getLineData = useMemo(() => {
    const memoedFetcher = async (search?: string) => {
      try {
        const res = await getTerminologiesByType<'Line'>('Line', {
          search,
        });

        setLines(res.results);
      } catch (error) {
        console.log(error);
      }
    };

    return debounce(memoedFetcher, 300);
  }, []);

  const getProcessData = useMemo(() => {
    const memoedFetcher = async (search?: string) => {
      try {
        const res = await getTerminologiesByType<'Process'>('Process', {
          search,
        });

        setProcesses(res.results);
      } catch (error) {
        console.log(error);
      }
    };

    return debounce(memoedFetcher, 300);
  }, []);

  useEffect(() => {
    if (open) {
      getLineData();
      getProcessData();
    }
  }, [open]);

  return (
    <CustomModal
      {...props}
      title={isEdit ? t('Edit terminology') : t('New terminology')}
      onOk={handleOk}
      onCancel={handleClose}
      onClose={handleClose}
    >
      <Spin spinning={isLoading}>
        <Form<CreateXRReqDTO>
          preserve={false}
          form={form}
          layout="vertical"
          validateTrigger={['onChange']}
          onFinish={handleFinish}
        >
          <Form.Item<CreateXRReqDTO>
            label={t('Control chart name ENG')}
            required
            name="xr_name_en"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter terminology')} />
          </Form.Item>
          <Form.Item<CreateXRReqDTO>
            label={t('Control chart name VIE')}
            required
            name="xr_name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Input placeholder={t('Enter terminology')} />
          </Form.Item>
          <Form.Item<CreateXRReqDTO>
            label={t('Line')}
            required
            name="line_id"
            rules={[
              {
                required: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Select
              placeholder={t('Choose line')}
              options={
                lines.map((item) => ({
                  label: (
                    <Typography.Text style={{ textWrap: 'wrap' }}>
                      {item.line_name} / {item.line_name_en}
                    </Typography.Text>
                  ),
                  value: item.id,
                })) as SelectProps['options']
              }
              showSearch
              allowClear
              onSearch={getLineData}
              filterOption={false}
            />
          </Form.Item>
          <Form.Item<CreateXRReqDTO>
            label={t('Process')}
            required
            name="process_equipment_id"
            rules={[
              {
                required: true,
                message: t('This field is required'),
              },
            ]}
          >
            <Select
              placeholder={t('Choose process')}
              options={
                processes.map((item) => ({
                  label: (
                    <Typography.Text style={{ textWrap: 'wrap' }}>
                      {item.process_equipment_name} /{' '}
                      {item.process_equipment_name_en}
                    </Typography.Text>
                  ),
                  value: item.id,
                })) as SelectProps['options']
              }
              showSearch
              allowClear
              onSearch={getProcessData}
              filterOption={false}
            />
          </Form.Item>
        </Form>
      </Spin>
    </CustomModal>
  );
}
