import { Fragment, useEffect, useRef, useState } from 'react';
import { Flex, Spin, Typography } from 'antd';

import { capitalize } from 'utils';
import { useChatStore } from 'stores/useChatStore';
import type { IChartType } from 'types';
import { useTranslation } from 'react-i18next';
import { GuideModal } from 'components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getMessages } from 'services/chat.service';
import { mapMessageData } from 'pages/Chat/utils';
import { CustomBubble, CustomChatContent } from './style';
import { ChartMessage, TextMessage } from '../Messages';
import { isTableDrawable } from '../Messages/utils';

export function ChatHistory() {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const {
    isSending,
    messages = [],
    chartError,
    curConversation,
    setMessages,
  } = useChatStore();
  const [isGuideModalOpen, toggleGuideModal] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [curPage, setCurPage] = useState(2);
  const [hasMore, toggleHasMore] = useState(true);

  const scrollToBottom = () => {
    const ele = document.getElementById('scrollableDiv');

    if (!ele) return;

    ele.scrollTop = ele.scrollHeight;
  };

  useEffect(() => {
    if (isSending) scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!curConversation) return;

    toggleHasMore(true);
    setCurPage(2);
  }, [curConversation]);

  const loadMoreData = () => {
    if (isLoading || !curConversation || !hasMore) return;

    setIsLoading(true);

    getMessages(curConversation.id, curPage)
      .then((res) => {
        if (!res.next) toggleHasMore(false);

        const mappedMsgs = mapMessageData(res.results);

        const newMessages = [...messages, ...mappedMsgs].sort((a, b) =>
          (a.created ?? 0) > (b.created ?? 0) ? 1 : -1,
        );

        setCurPage((prev) => prev + 1);
        setMessages(newMessages);

        setIsLoading(false);

        return res;
      })
      .catch(() => {
        setIsLoading(false);
        toggleHasMore(false);
      });
  };

  return (
    <CustomChatContent
      ref={chatEndRef}
      id="scrollableDiv"
      style={{
        height: '100%',
        overflowY: 'auto',
        width: '80%',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: 16,
      }}
    >
      <Spin fullscreen spinning={isLoading} />
      <InfiniteScroll
        inverse
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          width: '100%',
          minWidth: 700,
          margin: 'auto',
          overflowY: 'hidden',
        }}
        dataLength={messages.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<div />}
        scrollableTarget="scrollableDiv"
      >
        <Flex
          vertical
          gap={16}
          style={{ width: '100%', minWidth: 700, margin: 'auto' }}
        >
          {messages &&
            messages?.map((message) => {
              const { selectTimeLevelOption } = message;

              const isMetaDataEmpty =
                !message?.metaData ||
                Object.keys(message?.metaData).length === 0;

              const table =
                !isMetaDataEmpty &&
                message?.metaData[selectTimeLevelOption].table_content;

              return (
                <Fragment key={message.id}>
                  <TextMessage
                    message={message.question}
                    isResponse={false}
                    error={message.error}
                  />
                  {message.messageContent && (
                    <TextMessage message={message.messageContent} isResponse />
                  )}
                  {message.messageContent &&
                    !isMetaDataEmpty &&
                    table &&
                    isTableDrawable(table) && (
                      <ChartMessage
                        language={message.language}
                        metaData={message?.metaData}
                        defaultTimeLevel={selectTimeLevelOption}
                        timeLevelOptions={message.timeLevelOptions}
                        defaultChart={
                          message.chartType === ''
                            ? 'Table'
                            : (capitalize(message.chartType) as IChartType)
                        }
                        chartError={chartError}
                        textSQL={message.textSql}
                      />
                    )}
                </Fragment>
              );
            })}
          <div id="bottomChatDiv" />
          {isSending && <TextMessage message={<CustomBubble />} isResponse />}
        </Flex>
      </InfiniteScroll>
      <TextMessage
        message={
          <Typography.Text>
            {t(
              'Hi there! Do you know how to use the chatbot? If not, you can view the instructions',
            )}
            <Typography.Text
              onClick={() => toggleGuideModal(true)}
              style={{
                fontWeight: 700,
                color: '#2b8bb4',
                cursor: 'pointer',
              }}
            >
              {' '}
              {t('Here')}
            </Typography.Text>
          </Typography.Text>
        }
        isResponse
      />
      <GuideModal open={isGuideModalOpen} toggleOpen={toggleGuideModal} />
    </CustomChatContent>
  );
}
