import Chart from 'react-apexcharts';

import { useTranslation } from 'react-i18next';
import { useLangStore } from 'stores';
import { MeasurementMap } from 'pages/Chat/constants';
import type { BaseChartProps } from 'types';
import type { ApexOptions } from 'apexcharts';
import { extractColumnFromCountColumn } from 'pages/Chat/utils';
import type { ApexChartsTitleStyle } from '../types';

interface LineChartProps extends Omit<BaseChartProps, 'data'> {
  xUnit?: string;
  yUnit?: string;
  yUnit2?: string;
  language?: string;
  data: number[][];
}

function LineChart(props: LineChartProps) {
  const { xCategories, data, xUnit, yUnit, yUnit2, language } = props;
  const { t } = useTranslation();
  const { lang } = useLangStore();

  const lng = language ?? lang;

  const xAxisTitle = xUnit && t(`${xUnit}`, { lng });

  const yUnitExtracted = extractColumnFromCountColumn(yUnit);
  const yUnit2Extracted = extractColumnFromCountColumn(yUnit2);

  const yAxisTitle =
    yUnit &&
    MeasurementMap[yUnitExtracted] &&
    t(MeasurementMap[yUnitExtracted], { lng });
  const yAxisTitle2 =
    yUnit2 &&
    MeasurementMap[yUnit2Extracted] &&
    t(MeasurementMap[yUnit2Extracted], { lng });
  const xCatFormatted = xCategories[0].map((_, colIndex) =>
    xCategories.map((row) => row[colIndex]),
  );

  const titleStyle: ApexChartsTitleStyle = {
    fontSize: '10px',
    fontWeight: 'bold',
  };

  const hasSecondYAxis = yAxisTitle2 && data[0].length > 0;

  const yAxisArray: ApexOptions['yaxis'] = [
    {
      forceNiceScale: true,
      min: 0,
      labels: {
        style: {
          fontWeight: 500,
          fontSize: '10px',
          colors: hasSecondYAxis ? '#40A9FF' : 'inherit',
        },
      },
      ...(yUnit
        ? {
            title: {
              text: `(${yAxisTitle})`,
              style: titleStyle,
            },
          }
        : {}),
    },
  ];

  if (hasSecondYAxis) {
    yAxisArray.push({
      opposite: true,
      forceNiceScale: true,
      min: 0,
      labels: {
        style: {
          fontWeight: 500,
          fontSize: '10px',
          colors: '#f62225',
        },
      },
      title: {
        text: `(${yAxisTitle2})`,
        style: titleStyle,
      },
    });
  }

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      zoom: { enabled: false },
      animations: {
        enabled: false,
      },
    },
    states: {
      active: { filter: { type: 'none' } },
      hover: { filter: { type: 'none' } },
    },
    grid: {
      strokeDashArray: 2,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '10px',
        colors: ['#304758'],
      },
      textAnchor: 'middle',
      formatter: (val) => {
        if (String(val).length > 3) return `${String(val).slice(0, 3)}..`;

        return String(val);
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'straight',
      width: 3,
    },
    colors: ['#40A9FF', '#F5222D', '#3FA684'],
    ...(xAxisTitle
      ? {
          xaxis: {
            categories: xCatFormatted,
            axisTicks: { show: false },
            labels: {
              style: { fontWeight: 500, fontSize: '10px' },
              hideOverlappingLabels: false,
              trim: true,
              minHeight: 100,
              maxHeight: 150,
            },
            ...(xUnit
              ? {
                  title: {
                    text: xAxisTitle,
                    style: titleStyle,
                  },
                }
              : {}),
          },
        }
      : {}),
    ...(yAxisTitle
      ? {
          yaxis: yAxisArray,
        }
      : {}),
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        opacityFrom: 0.3,
        opacityTo: 0.3,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      followCursor: true,
    },
  };

  const series = data.map((item, idx) => ({
    data: item,
    name: `Line ${idx + 1}`,
    type: data[0].length === 1 ? 'line' : 'area',
  }));

  return <Chart options={options} series={series} type="area" />;
}

export default LineChart;
